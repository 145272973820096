<template>
	<div>
		<div class="cashier-box cashier-box--deposit">
			<div class="content-wrapper">
				<div class="qr-wrapper">
					<a :href="linkUrl">
						<img class="qr-wrapper__image" :src="qrUrl" alt="qr-code" />
					</a>
				</div>
				<div class="content">
					<h3 class="content__title">
						{{
							$t('deposit.coinspaid.address-heading', {
								accountCurrency: accountCurrency,
								currency: currency,
							})
						}}
					</h3>
					<p class="content__description">
						{{
							$t('deposit.coinspaid.address-note', {
								accountCurrency: accountCurrency,
								currency: currency,
							})
						}}
					</p>
				</div>
			</div>
			<div class="address">
				<span class="address__data">{{ address }}</span>
				<span class="address__copy-button" :class="{ 'address__copy-button--copied': copied }" @click="copyAddress()">
					<img src="../../../../assets/icons/copy.svg" alt="copy-icon" class="address__copy-button-icon" />
					<span class="address__copy-button-label">{{ copied ? $t('actions.copied') : $t('actions.copy') }}</span>
				</span>
			</div>
			<button type="button" class="button primary-button deposit center" @click="done()">
				{{ $t('deposit.done') }}
			</button>
		</div>

		<div class="method-description-cashier">
			{{ $t(`deposit.method-descriptions.coinspaid`) }}
		</div>
	</div>
</template>
<script>
export default {
	name: 'BitstampAddress',
	props: {
		address: {
			type: String,
			required: true,
		},
		currency: {
			type: String,
			required: true,
		},
		accountCurrency: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			copied: false,
		};
	},
	computed: {
		qrUrl() {
			return `https://api.qrserver.com/v1/create-qr-code/?size=170x170&data=bitcoin:${this.address}`;
		},
		linkUrl() {
			return `bitcoin:${this.address}`;
		},
	},
	methods: {
		done() {
			const walletId = this.$route.params.walletId;

			this.$router.push({
				path: `/payments/deposit/${walletId}/confirmation`,
				query: { status: 'success', method: 'coinspaid' },
			});
		},
		copyAddress() {
			const el = document.createElement('textarea');
			el.value = this.address;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
			this.copied = true;
			setTimeout(() => {
				this.copied = false;
			}, 1600);
		},
	},
};
</script>
<style scoped lang="scss">
@import 'src/assets/style/_variables';

.cashier-box {
	padding: 20px;
}

.content-wrapper {
	display: flex;

	@media screen and (max-width: 420px) {
		flex-direction: column-reverse;
	}
}

.qr-wrapper {
	width: 150px;
	margin-right: 20px;

	@media screen and (max-width: 420px) {
		margin: 0 auto;
	}

	&__image {
		width: 100%;
	}
}

.content {
	flex: 1;
	text-align: left;

	&__title {
		margin-top: 0;

		@media screen and (max-width: 420px) {
			text-align: center;
		}
	}
}

.address {
	display: flex;
	align-items: stretch;
	justify-content: center;
	margin-top: 20px;
	border-radius: 3px;
	overflow: hidden;

	@media screen and (max-width: $xl + 1) {
		font-size: 15px;
	}

	&__data,
	&__copy-button {
		border: 1px solid #ffffff;
		min-height: 40px;
		padding: 0 15px;
		margin-bottom: 0;
	}

	&__data {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		word-break: break-word;
		border-right: 0;
		border-bottom-left-radius: 3px;
		border-top-left-radius: 3px;
		user-select: all;

		@media screen and (max-width: $md) {
			justify-content: center;
			flex: 1;
		}
	}

	&__copy-button {
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
		background-color: #595959;
		padding: 5px 15px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		&--copied {
			background-color: #70c157;
		}

		&-icon {
			height: 17px;
			margin-right: 0.3rem;
		}

		@media screen and (max-width: $md) {
			&-label {
				display: none;
			}
			&-icon {
				margin-right: 0;
			}
		}
	}
}

.primary-button.deposit {
	margin-top: 20px;
}
</style>
